<template>
  <div class="page-body">
      <title-bar title="燃气表入库管理">
        <el-button type="primary" @click="edit()" icon="fa fa-plus-circle" :loading="loading">
          添加
        </el-button>
      </title-bar>
      <el-form ref="queryForm" v-model="queryForm" inline label-width="80px">
        <el-form-item label="班组名称">
          <code-select v-model="queryForm.team" code-type="team" @change="query" style="width: 150px"></code-select>
        </el-form-item>
        <el-form-item label="表型号">
          <code-select v-model="queryForm.meterType" code-type="meterType" @change="query" style="width: 150px"></code-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="queryForm.finished" clearable @change="query" style="width: 150px">
            <el-option value="true" label="已完成"></el-option>
            <el-option value="false" label="未完成"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库日期">
          <el-date-picker v-model="dates" type="daterange" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label-width="0px">
          <el-button type="primary" @click="query()" icon="fa fa-search" :loading="loading">
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <el-table stripe :data="records" :border="true" v-loading="loading">
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="dt" label="入库日期" min-width="100" align="center"/>
        <el-table-column prop="meterTypeName" label="燃气表型号" align="center" min-width="150"/>
        <el-table-column prop="teamName" label="班组名称" align="center" min-width="120"/>
        <el-table-column prop="totalCount" label="总表数" align="center" min-width="75"/>
        <el-table-column prop="hangupCount" label="已挂表" align="center" min-width="75"></el-table-column>
        <el-table-column prop="leftCount" label="未挂表" align="center" min-width="75" v-slot="{row}">
          {{row.totalCount - row.hangupCount}}
        </el-table-column>
        <el-table-column prop="state" label="状态" align="center" min-width="70" v-slot="{row}">
          <span v-if="row.finished" class="color-success">已完成</span>
          <span v-else class="color-error">未完成</span>
        </el-table-column>
        <el-table-column prop="log" label="完成" min-width="130" align="center" v-slot="{row}">
          <el-link type="primary" @click="edit(row)">修改</el-link>
          <el-link type="danger" @click="del(row)">删除</el-link>
          <el-link type="success" v-if="!row.finished" @click="finish(row, true)">标记完成</el-link>
          <el-link type="warning" v-else @click="finish(row, false)">撤回标记</el-link>
          <el-link type="primary" @click="toExcel(row)">导出</el-link>
        </el-table-column>
      </el-table>
      <table-pagination :page-query="queryForm" :total="total" @change="loadData"></table-pagination>
    </div>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";
import TitleBar from "@/components/base/TitleBar.vue";
import TablePagination from "@/components/base/TablePagination.vue";
import CodeSelect from "@/components/base/CodeSelect.vue";
import QRCode from "qrcode";
export default {
  components: {CodeSelect, TablePagination, TitleBar},
  extends: BaseVue,
  data() {
    return {
      records: [],
      dates: null,
      total: 0,
      queryForm: {
        startDate: null,
        endDate: null,
        team: null,
        meterType: null,
        finished: null,
        page: 1,
        limit: 10
      }
    }
  },
  created() {
    this.query();
  },
  methods: {
    loadData() {
      this.loading = true;
      if(this.dates) {
        this.queryForm.startDate = this.dates[0];
        this.queryForm.endDate = this.dates[1];
      }else{
        this.queryForm.startDate = null;
        this.queryForm.endDate = null;
      }
      this.postForm("/gas-meter-in-bill/list", this.queryForm, true).then((dr) => {
        this.records = dr.rows || [];
        this.total = dr.total || 0;
      }).finally(() => {
        this.loading = false;
      });
    },
    query() {
      this.queryForm.page = 1;
      this.loadData();
    },
    edit(row){
      this.$router.push({
        path: "/gas-meter-in-edit",
        query: {id: row?row.id:""}
      });
    },
    del(row){
      this.$confirm("确定要删除此入库单吗？", "提示", {
        type: "warning"
      }).then(()=>{
        this.loading = true;
        this.postForm("/gas-meter-in-bill/delete", {
          id: row.id
        }, true).then(dr=>{
          this.loadData();
        }).finally(dr=>{
          this.loading = false;
        });
      })
    },
    finish(row, finished) {
      let message = finished ? "确定要标记为已完成吗？" : "确定要撤销标记吗？";
      this.$confirm(message,"系统提示", {type:'info'}).then(()=>{
        this.loading = true;
        this.postForm("/gas-meter-in-bill/finish", {
          id: row.id,
          finished: finished
        }, true).then(dr=>{
          this.loadData();
        }).finally(dr=>{
          this.loading = false;
        });
      })
    },
    toExcel(row){
      window.open(this.globalConfig.apiRoot+"/gas-meter-in-bill/download?id="+row.id);
    }
  }
}
</script>

<style scoped lang="scss">
</style>
